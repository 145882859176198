// for dev.
export const APILINK = "https://supplier.div-systems.com";
export const WEBSOCKETS_LINK = "wss://supplier.div-systems.com";

// for production
// export const APILINK = "https://back-supplier.yanzo.com";
// export const WEBSOCKETS_LINK = "wss://back-supplier.yanzo.com";

// export const APILINK = "https://plenty-maps-worry-197-52-98-194.loca.lt";
// export const WEBSOCKETS_LINK = "wss://plenty-maps-worry-197-52-98-194.loca.lt";
//

// for dev.
// export const WEBSOCKETS_LINK = "ws://localhost:3333";
// export const APILINK = "http://localhost:3333";
